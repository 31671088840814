
//本地引入
import errGif from "@/assets/401-images/401.gif";

//公共库
import { Component, Vue } from "vue-property-decorator";

//组件
@Component({
  name: "Page401",
})

//组件函数
export default class extends Vue {
  //定义变量
  private errGif = errGif + "?" + new Date();

  //返回按钮
  private back(): void {
    //退回上一页
    if (this.$route.query.noGoBack) {
      this.$router.push({ path: "/dashboard" }).catch((err) => {
        console.warn(err);
      });
    } else {
      this.$router.go(-1);
    }
  }
}
